import React, { useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Form, Row, Container, Button  } from 'react-bootstrap';
import axios from 'axios';
import { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginDetails } from './actions/index';
import { notify, saveToLocalStorage, saveToSessionStorage, loadFromSessionStorage, removeFromSessionStorage, encrypt, decrypt, is_user_is_login } from "./utils";
import Loader from './components/loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

function Login() {
    const location = useLocation();
    const currentRoute = location.pathname;
    const navigate = useNavigate();
   
    

    const dispatch = useDispatch();
    const user_id = useSelector((state) => state.get_user_id);
    const usernameRef = useRef('');
    const passRef = useRef('');

    const [isSubmitDisabled,setisSubmitDisabled] = useState(false);

    const [login_error_message, set_login_error_message] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        set_login_error_message('');
        setisSubmitDisabled(true);
        let formData = {
            phone: usernameRef.current.value,
            password: passRef.current.value
        };
        
        const response = await axios.post('https://stock.wizardinfosys.in/api/login', formData).then(response => {
            setisSubmitDisabled(false);
            if(response.data.data !== undefined)
            {
                saveToLocalStorage('user_id',response.data.data.id);
                saveToLocalStorage('user_details',response.data.data);
                saveToLocalStorage('phone',response.data.data.phone);
                saveToLocalStorage('auth',encrypt(passRef.current.value));
                dispatch(setLoginDetails(response.data.data.id));
                navigate('/categories/');
            }
        })
        .catch(error => {
            if (axios.isAxiosError(error) && error.response) {
                setisSubmitDisabled(false);
                notify(toast,'error',error.response.data.message);
            } else {
                setisSubmitDisabled(false);
                notify(toast,'error','Server error! Please try again later.');
            }
            
        });
            
        
        
           
    }


    return (
        <>
            <Helmet>
                <title>Login - Stock Inventody</title>
            </Helmet>
            <Loader></Loader>
            <ToastContainer />            
            <Container>
                <div className="login_form_section">
                    <div className="login_form_inner">
                        <Row className="justify-content-center">
                            <Col xs md="8" lg="5">
                                <div className="form_block">
                                    <h2>LOGIN</h2>

                                    <Form onSubmit={handleSubmit} autoComplete="off">
                                        <Form.Group className="form_input_block" controlId="formGroupEmail">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="number" ref={usernameRef} placeholder="Enter usename" autoComplete="off" />
                                        </Form.Group>
                                        <Form.Group className="form_input_block" controlId="formGroupPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" ref={passRef} placeholder="Password" autoComplete="off"/>
                                        </Form.Group>
                                        <Button variant="primary" type="submit" disabled={isSubmitDisabled}> Login</Button>
                                    </Form>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
);
}

export default Login;

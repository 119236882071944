import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {is_user_login} from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Loader = (props) => {
    const [login_check,set_login_check] = useState(false);
    const location = useLocation();
    const currentRoute = location.pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {

        const loginValidation = async () => {
            try {
                const response = await is_user_login(dispatch,currentRoute);
                if(response !== true)
                {
                    navigate('/');
                }
                else{
                    if(currentRoute == '/')
                    {
                        navigate('/categories');
                    }
                }
                set_login_check(true);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          loginValidation();
    },[]); // Empty dependency array

    useEffect(() => {
    },[props])

    useEffect(() => {
        if(props.allow_access === false && login_check === false)
        {
        }
    },[login_check,props]);

    useEffect(() => {
    });



    return (
        <>
            {/* {props.allow_access == true ? 'true' : 'false'} */}
            {/* {login_check == true ? 'true' : 'false'} */}
            {
                (props.allow_access == false || login_check === false)
                ?
                    (<div className="loader_wrapper" style={{height:'100vh',display: 'flex',justifyContent: 'center',alignItems: 'center',background: 'rgb(243 232 232)',position:'fixed',width:'100%',zIndex:99999999999}}>
                        <Spinner  animation="border" role="status" style={{color:'#000'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>)
                : null
            }
        </>
    );
}

export default Loader;
import React, { useEffect, useState, useRef } from 'react';
import { is_user_is_login, check_access, notify } from '../../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/loader';
import Header from '../../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Form, Row, Container, Button, Navbar, ListGroup, Dropdown, Table } from 'react-bootstrap';
import Logo from '../../assets/images/logo.svg';
import Chevronright from '../../assets/images/chevron-right.svg';
import Dots from '../../assets/images/dots.svg';
import Search from '../../assets/images/search-ic.svg';
import Addnew from '../../assets/images/add_new_ic.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Add = () => {

  const categoryRef = useRef('');
  const formRef = useRef('');
  const [isSubmitDisabled, setisSubmitDisabled] = useState(false);
  const [category_error, set_category_error] = useState('');
  const [error_message, set_error_message] = useState('');
  const user_id = useSelector((state) => state.set_user_id);
  const user_data = useSelector((state) => state.set_user_data);
  const location = useLocation();
  const currentRoute = location.pathname;
  const navigate = useNavigate();
  const [login_check, set_login_check] = useState(false);
  const [allow_access, set_allow_access] = useState(false);
  const [categories, set_categories] = useState([]);
  const formData = {
    user_id: user_id,
    store_id: user_data.store_id
  };
  const [access, set_access] = useState([]);

  useEffect(() => {
  });

  useEffect(() => {
    if (user_id !== undefined && user_data.store_id !== undefined) {
      set_access(user_data.access.split(','));
      const get_categories = async () => {
        const response = await axios.post(`${API_URL}api/category/getAll`, { user_id: user_id, store_id: user_data.store_id }).then(response => {
          if (response.data.status == 'success') {
            set_categories(response.data.message);
          }
        })
          .catch(error => {
            if (axios.isAxiosError(error) && error.response) {
              // setErrorMessage(error.response.data.message);
            } else {
              console.error(error);
            }

          });
      }
      get_categories();
    }
  }, [user_id, user_data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_category_error('');
    // setisSubmitDisabled(true);
    if (categoryRef.current.value == '') {
      set_category_error('Required field.');
    } else {
      setisSubmitDisabled(true);;
      const formData = {
        user_id: user_id,
        store_id: user_data.store_id,
        name: categoryRef.current.value
      };
      const response = await axios.post(`${API_URL}api/category/create`, formData).then(response => {
        setisSubmitDisabled(false);
        if (response.data !== undefined && response.data.status == 'success') {
          formRef.current.reset();
          notify(toast,'success', response.data.message);
        }
      })
        .catch(error => {
          if (axios.isAxiosError(error) && error.response) {
            set_error_message(error.response.data.message);
            setisSubmitDisabled(false);
            // setErrorMessage(error.response.data.message);

            notify(toast,'error', error.response.data.message);
          } else {
            console.error(error);
            set_error_message('Server error! Please try again later.');
            setisSubmitDisabled(false);
            notify(toast,'error', 'Server error! Please try again later.');
          }

        });
    }
  }



  useEffect(() => {
    if(user_data !== undefined && user_data.access !== undefined && user_data.access != '')
    {
      let total_access = user_data.access.split(',');
      if(total_access.includes('AddCategory'))
      {
        set_allow_access(true);
      }else{
        set_allow_access(false);
        navigate('/categories');
      }
    }else{
      set_allow_access(false);
      navigate('/categories');
    }
  },[login_check,user_data]);

  return (
    <>

      <Loader login_check={login_check} allow_access={allow_access}></Loader>

      <Header></Header>
      <ToastContainer />
      <Container>
        <div className="category_list_block my-3 py-5">
          <Row className="justify-content-center">
            <Col xs md="10">
              <div className="category_list_inner">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Form.Group className="form_input_block" controlId="formGroupEmail">
                    <Form.Label>Category Name</Form.Label>
                    <Form.Control type="text" ref={categoryRef} placeholder="Enter category name" />
                    {
                      category_error != '' ?
                        (<p className="text-danger">{category_error}</p>)
                        : null
                    }
                  </Form.Group>

                  <Button variant="primary" type="submit" disabled={isSubmitDisabled}> Add</Button>
                  <Button onClick={() => navigate('/categories')} variant="secondary ms-3" type="submit"> Back</Button>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

    </>
  );
};

export default Add;
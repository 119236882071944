// Common functions will be here...

// cryptoUtils.js
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {setLoginDetails, setUserDetails} from './actions/index';
// Key should be stored securely and not hardcoded in production
const secretKey = 'wizardstock';

// Encrypt function
export const encrypt = (text) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

// Decrypt function
export const decrypt = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
// Save data to local storage
export const saveToLocalStorage = (key, value) => {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error("Error saving to local storage", error);
    }
};
  
// Load data from local storage
export const loadFromLocalStorage = (key) => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
        return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error("Error loading from local storage", error);
        return undefined;
    }
};
  
// Remove data from local storage
export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing from local storage", error);
    }
};

// Save data to session storage
export const saveToSessionStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error("Error saving to session storage", error);
    }
};
  
// Load data from session storage
export const loadFromSessionStorage = (key) => {
    try {
        const serializedValue = sessionStorage.getItem(key);
        if (serializedValue === null) {
        return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error("Error loading from session storage", error);
        return undefined;
    }
};
  
// Remove data from session storage
export const removeFromSessionStorage = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing from session storage", error);
    }
};

export const logout = () => {
    removeFromLocalStorage('user_id');
    removeFromLocalStorage('auth');
    removeFromLocalStorage('phone');
    removeFromLocalStorage('user_details');
}

// Lets check if user is login or not
export const is_user_login = async(dispatch,route) =>
{    

    let user_id = loadFromLocalStorage('user_id');
    let phone = loadFromLocalStorage('phone');
    let password = loadFromLocalStorage('auth') !== undefined ? decrypt(loadFromLocalStorage('auth')) : '';
    let result = false;
    if(user_id !== undefined && password !== undefined && phone !== undefined)
    {
        let formData = {
            phone : phone,
            password : password
        }
        const response = await axios.post('https://stock.wizardinfosys.in/api/login', formData).then(response => {
            if(response.data.data !== undefined)
            {
                if(response.data.data.id == user_id)
                {
                    saveToLocalStorage('user_id',response.data.data.id);
                    saveToLocalStorage('user_details',response.data.data);
                    saveToLocalStorage('phone',response.data.data.phone);
                    saveToLocalStorage('auth',encrypt(password));
                    dispatch(setLoginDetails(response.data.data.id));
                    dispatch(setUserDetails(response.data.data));
                    result = true;
                }
            }
        })
        .catch(error => {
            result = false;        
        });
        if(result == false)
        {
            logout();
        }
        return result; 
    }
    
}

export const check_access = (all_access,want_access) => {
    if(all_access.contains(want_access))
    {
        return true;   
    }
    return false;
}

export const notify = (toast,error_type, error_message) => {
    switch (error_type) {
      case 'error':
        toast.error(error_message, {
          position: 'bottom-center',
          autoClose: 1500,
        });
        break;

      case 'success':
        toast.success(error_message, {
          position: 'bottom-center',
          autoClose: 1500,
        });
        break;

      default:
        break;
    }
  }
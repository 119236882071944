const initialValue = '';

const set_user_id = (state = initialValue,action) =>{
    switch (action.type) {
        case "SET_USER_ID":            
            return action.payload;
            break;        
    
        default:
            return state;
            break;
    }
}



export default set_user_id;
import React, {useState, useEffect, useRef} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/loader';
import Header from '../../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../constants';
import { notify } from '../../utils';
import { useNavigate, useLocation, useParams, Link  } from 'react-router-dom';
import axios from 'axios';
import { Col, Form, Row, Container, Button, Navbar, ListGroup, Dropdown, Table, Modal } from 'react-bootstrap';
import Angleright from '../../assets/images/arrow-angle-right.svg';
import DeleteIc from '../../assets/images/delete-ic.svg';
import EditIc from '../../assets/images/edit-ic.svg';
import DeleteDarkIc from '../../assets/images/delete-dark-ic.svg';
import SaveIc from '../../assets/images/save-ic.svg';
import Chevronright from '../../assets/images/chevron-right.svg';
import Addnew from '../../assets/images/add_new_ic.svg';
import Search from '../../assets/images/search-ic.svg';
import {is_user_login} from '../../utils';
import {Helmet} from "react-helmet";

const ViewProducts = () => {

    const inputRef = useRef('');
    const add_product_input_ref = useRef('');
    const edit_product_input_ref = useRef('');
    const [add_product_input,set_add_product_input] = useState('');
    const [edit_product_input,set_edit_product_input] = useState('');

    const user_id = useSelector((state) => state.set_user_id);
    const user_data = useSelector((state) => state.set_user_data);
    const [access, set_access] = useState([]);
    const [inputText,setinputText] = useState('');
    const [searching,set_searching] = useState(false);
    const [allow_access,set_allow_access] = useState(false);
    const navigate = useNavigate();
    const { cat_id } = useParams();
    const [products,set_products] = useState([]);
    let typeCheck = undefined;
    const [modal_show,set_modal_show] = useState(false);
    const [delete_modal_show,set_delete_modal_show] = useState(false);
    const [edit_modal_show,set_edit_modal_show] = useState(false);
    const [add_product_btn_disabled,set_add_product_btn_disabled] = useState(false);
    const [delete_product_btn_disabled,set_delete_product_btn_disabled] = useState(false);
    const [update_product_btn_disabled,set_update_product_btn_disabled] = useState(false);
    const [add_product_input_error,set_add_product_input_error] = useState('');
    const [edit_product_input_error,set_edit_product_input_error] = useState('');
    const [delete_product_name,set_delete_product_name] = useState('');
    const [delete_product_id,set_delete_product_id] = useState('');
    const [edit_product_id,set_edit_product_id] = useState('');
    const [breadcrumb_product_name,set_breadcrumb_product_name] = useState('');

    const inputChange = () => {
        clearTimeout(typeCheck);
        typeCheck = setTimeout(function(){
            set_searching(true);
            if(setinputText !== undefined)
            {
                setinputText(inputRef.current.value);
            }

            if(set_searching !== undefined)
            {
                set_searching(true);
            }

        },1000);
    }

    const modal_close = () => {
        set_modal_show(false);
    }

    const delete_modal_close = () => {
        set_delete_modal_show(false);
    }

    const edit_modal_close = () => {
        set_edit_modal_show(false);
    }

    useEffect(() => {
        if(searching == true)
        {
            filter_category();
        }
    },[searching]);

    const filter_category = async() => {
        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            search: inputText,
            category_id: cat_id
        };
        const response = await axios.post(`${API_URL}api/product/search`, formData).then(response => {
            if (response.data.status == 'success') {
                set_products(response.data.data);
            }
        })
        .catch(error => {
            set_products([]);
        });
        setTimeout(function(){
            set_searching(false);
        },0);
    }

    useEffect(() => {
        if (user_id !== undefined && user_data.store_id !== undefined) {
            verify_category();
            set_access(user_data.access.split(','));
        }
    },[user_id,user_data]);

    // Verify that category is available or not
    const verify_category = async () => {
        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            category_id: cat_id
        };
        const response = await axios.post(`${API_URL}api/category/getCategory`, formData).then(response => {
            if (response.data.status == 'success' && response.data.message.id == cat_id && response.data.message.store_id == user_data.store_id) {
                get_products();
                set_breadcrumb_product_name(response.data.message.name);
            }else{
                navigate('/categories');
            }
        })
        .catch(error => {
            set_allow_access(true);
            if (axios.isAxiosError(error) && error.response) {
                // setErrorMessage(error.response.data.message);
                navigate('/categories');
            } else {
                navigate('/categories');
            }

        });
    }

    // Get products
    const get_products = async() => {

        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            category_id: cat_id
        };
        const response = await axios.post(`${API_URL}api/product/getAll`, formData).then(response => {
            if (response.data.status == 'success') {
                set_products(response.data.data.reverse());
            }
            set_allow_access(true);
        })
        .catch(error => {
            notify(toast,'error','Server error! please try again later');
            set_allow_access(true);
        });
    }

    // Edit product
    const edit_product = async(edit_product_id,product_name) => {
        set_edit_product_input_error('');
        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            category_id: cat_id,
            product_id: edit_product_id,
        };
        const response = await axios.post(`${API_URL}api/product/getProduct`, formData).then(response => {
            if (response.data.status == 'success') {
                set_edit_product_input(response.data.data.name);
            }
        })
        .catch(error => {
            set_delete_modal_show(false);
            if (axios.isAxiosError(error) && error.response) {
                notify(toast,'error',error.response.data.message);
            } else {
                notify(toast,'error','Server error! Please try again later.');
            }
        });
        set_edit_modal_show(true);
    }

    const update_product = async () => {
        set_edit_product_input_error('');
        if(edit_product_input_ref.current.value == '')
        {
            set_edit_product_input_error('Required field.');
        }else{
            set_update_product_btn_disabled(true);
            const formData = {
                user_id: user_id,
                store_id: user_data.store_id,
                category_id: cat_id,
                product_id: edit_product_id,
                name: edit_product_input_ref.current.value
            };
            const response = await axios.post(`${API_URL}api/product/update`, formData).then(response => {
                if (response.data.status == 'success') {
                    notify(toast,'success',response.data.message);
                    get_products();
                }
                set_update_product_btn_disabled(false);

            })
            .catch(error => {
                set_update_product_btn_disabled(false);
                set_delete_modal_show(false);
                if (axios.isAxiosError(error) && error.response) {
                    notify(toast,'error',error.response.data.message);
                } else {
                    notify(toast,'error','Server error! Please try again later.');
                }
            });
            set_edit_modal_show(true);
        }
    }

    useEffect(() => {
    },[edit_product_input]);

    // Delete product
    const delete_product = async() => {
        set_delete_product_btn_disabled(true);

        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            category_id: cat_id,
            product_id: delete_product_id,
        };
        const response = await axios.post(`${API_URL}api/product/delete`, formData).then(response => {
            if (response.data.status == 'success') {
                set_delete_modal_show(false);
                notify(toast,'success',response.data.status);
                get_products();
            }
        })
        .catch(error => {
            set_delete_modal_show(false);
            if (axios.isAxiosError(error) && error.response) {
                notify(toast,'error',error.response.data.message);
            } else {
                notify(toast,'error','Server error! Please try again later.');
            }
        });
    }

    // deleteConfirm
    const deleteConfirm = () => {
        set_delete_modal_show(true);
    }

    const add_product = async() => {
        set_add_product_input_error('');
        if(add_product_input_ref.current.value != '')
        {
            set_add_product_btn_disabled(true);
            const formData = {
                user_id: user_id,
                store_id: user_data.store_id,
                category_id: cat_id,
                name: add_product_input_ref.current.value
            };
            const response = await axios.post(`${API_URL}api/product/create`, formData).then(response => {
                if (response.data.status == 'success') {
                    set_modal_show(false);
                    notify(toast,'success',response.data.status);
                    set_add_product_btn_disabled(false);
                    get_products();
                }
            })
            .catch(error => {
                set_add_product_btn_disabled(false);
                if (axios.isAxiosError(error) && error.response) {
                    notify(toast,'error',error.response.data.message);
                } else {
                    notify(toast,'error','Server error! Please try again later.');
                }
            });
        }else{
            set_add_product_input_error('Required field.');
        }
    }

    const open_product_modal = () => {
        if(access.includes('AddProduct'))
        {
            set_modal_show(true);
        }else{
            notify(toast,'error','You didn\'t have access to add new product');
        }
    }

    useEffect(() => {
        set_allow_access(false);
        if (user_id !== undefined && user_data.store_id !== undefined) {
            verify_category();
        }
    },[cat_id]);

    return(
        <>
            <Helmet>
                <title>Products - Stock Inventody</title>
            </Helmet>
            <Loader allow_access={allow_access}/>
            <ToastContainer />
            <Header inputText={inputText} setinputText={setinputText} searching={searching} set_searching={set_searching} active_bc='product' breadcrumb_product_name={breadcrumb_product_name}/>

            <Container>
                <div className="category_list_block my-3 py-5">
                    <Row className="justify-content-center">
                        {

                            (<Col className="col-md-10 col d-flex justify-content-between mb-3">
                                <div className="d-flex align-items-center search_bar position-relative">
                                    <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    className=" mr-sm-2 search_field"
                                    ref={inputRef}
                                    onKeyUp={() => {inputChange()}}
                                    disabled={(searching !== undefined && searching == true ) ? true : false}
                                    />
                                    <img
                                    src= {Search}
                                    width="20"
                                    height="20"
                                    className="d-inline-block align-top search_icon"
                                    alt="search icon"
                                    />
                                </div>
                                {
                                    access.includes('AddProduct') ?
                                    <Button onClick={() => open_product_modal()} className="primary mt-sm-0 mt-2 d-flex justify-content-around align-items-center add_new_btn ">
                                        <img src= {Addnew} width="15" height="15" className="d-inline-block" alt="add icon"/> ADD NEW
                                    </Button>
                                    : null
                                }

                            </Col>)
                        }
                        <Col xs md="10">
                            <div className="category_list_inner">
                                <Table responsive="sm" size="sm" className={searching == true ? 'catgeory_list_group searching' : 'catgeory_list_group'}>
                                    <thead>
                                        <tr>
                                            <th style={{width:"45px"}}></th>
                                            <th style={{textAlign:"left"}}>Products</th>
                                            {
                                                access.includes("UpdateProduct") || access.includes("DeleteProduct")
                                                ?
                                                    <th className="option_item">Options</th>
                                                : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products !== undefined && products.length > 0
                                            ?
                                                products.map((product,product_key) => {
                                                    return (
                                                        <tr key={product_key}>
                                                            <td>
                                                                <div className="m-auto category_link">
                                                                    <Link to="#" className="d-flex align-items-center justify-content-center">
                                                                        <img
                                                                            src={Angleright}
                                                                            width="15"
                                                                            height="15"
                                                                            className="d-inline-block align-top"
                                                                            alt="Arrow Angler right"
                                                                        /></Link>
                                                                </div>
                                                            </td>
                                                            <td><Link to={`/products/${product.id}/${cat_id}`}>{product.name}</Link></td>
                                                            {
                                                                access.includes("UpdateProduct") || access.includes("DeleteProduct")
                                                                ?
                                                                    <td className="option_data">
                                                                        <div className="ms-auto category_options">
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="light" id="dropdown-1" className="toggle_dots m-auto">
                                                                                    Actions
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    {
                                                                                        access.includes("UpdateProduct") ? (<Dropdown.Item onClick={() => {set_edit_product_id(product.id);edit_product(product.id,product.name);}}><img src={EditIc} width="15" height="15" className="d-inline-block me-2" alt="Edit Icon"/> Edit</Dropdown.Item>) : null
                                                                                    }
                                                                                    {
                                                                                        access.includes("DeleteProduct") ? (<Dropdown.Item onClick={() => {deleteConfirm(product.id,product.name);set_delete_product_name(product.name);set_delete_product_id(product.id)}}><img src={DeleteDarkIc} width="15" height="15" className="d-inline-block me-2" alt="Delete Icon"/> Delete</Dropdown.Item>) : null
                                                                                    }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                : null


                                                            }

                                                        </tr>
                                                    )
                                                })
                                            :   (<tr>
                                                    <td colSpan={access.includes("UpdateProduct") || access.includes("DeleteProduct") ? 3 : 2}>
                                                        <h5 className="m-auto text-center">
                                                            No products found
                                                        </h5>
                                                    </td>
                                                </tr>)
                                        }

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Modal show={modal_show} onHide={modal_close}>
                <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="form_input_block" controlId="formGroupEmail">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" ref={add_product_input_ref} defaultValue={add_product_input} placeholder="Product Name" onKeyUp={() => {set_add_product_input_error('')}} />
                        {add_product_input_error != '' ? (<div className="text-danger mt-2 ms-2">{add_product_input_error}</div>) : null}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={modal_close}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {add_product()}} disabled={add_product_btn_disabled == true ? true : false}>
                <img src= {Addnew} width="15" height="15" className="d-inline-block" alt="add icon" /> Add Product
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={delete_modal_show} onHide={delete_modal_close}>
                <Modal.Header closeButton>
                <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want to delete product <strong>{delete_product_name}</strong>?</h5>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={delete_modal_close}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {delete_product()}} disabled={delete_product_btn_disabled == true ? true : false}>
                    <img src={DeleteIc} width="15" height="15" className="d-inline-block" alt="Delete Icon"/>  Delete Product
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={edit_modal_show} onHide={edit_modal_close}>
                <Modal.Header closeButton>
                <Modal.Title>Update Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="form_input_block" controlId="formGroupEmail">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" ref={edit_product_input_ref} defaultValue={edit_product_input} placeholder="Product Name" onKeyUp={() => {set_edit_product_input_error('')}} />
                        {edit_product_input_error != '' ? (<div className="text-danger mt-2 ms-2">{edit_product_input_error}</div>) : null}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={edit_modal_close}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {update_product()}} disabled={update_product_btn_disabled == true ? true : false}>
                    <img src={SaveIc} width="15" height="15" className="d-inline-block" alt="Save Icon"/> Update Product
                </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ViewProducts;
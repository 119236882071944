// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewCategory from './pages/category/view';
import AddCategory from './pages/category/add';
import ViewProducts from './pages/product/view';
import ViewProductMeta from './pages/product_meta/view';
import { Link } from 'react-router-dom';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/categories" element={<><ViewCategory /></>}/>
        <Route path="/categories/add" element={<AddCategory />}/>
        <Route path="/categories/:cat_id" element={<><ViewProducts /></>}/>
        <Route path="/products/:product_id/:cat_id" element={<><ViewProductMeta /></>}/>
      </Routes>
    </BrowserRouter>

  );
}

export default App;

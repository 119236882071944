import React, {useEffect, useRef, useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import { logout, is_user_is_login } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Form, Row, Container, Button, Navbar, ListGroup, Dropdown, Table } from 'react-bootstrap';
import Logo from '../assets/images/logo.svg';
import Logout_Icon from '../assets/images/logout-ic.svg';
import Dots from '../assets/images/dots.svg';
import Search from '../assets/images/search-ic.svg';
import Addnew from '../assets/images/add_new_ic.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const Header = (props) => {
    const { product_id, cat_id } = useParams();
    const navigate = useNavigate();
    const user_id = useSelector((state) => state.set_user_id);
    const user_data = useSelector((state) => state.set_user_data);
    const inputRef = useRef('');
    let typeCheck = undefined;
    const [searching,set_searching] = useState(false);
    const [inputText,setinputText] = useState('');
    const [access, set_access] = useState([]);
    // useEffect(() => {
    // },[inputChange]);

    const inputChange = () => {
        clearTimeout(typeCheck);
        typeCheck = setTimeout(function(){
            set_searching(true);
            if(props.setinputText !== undefined)
            {
                props.setinputText(inputRef.current.value);
            }

            if(props.set_searching !== undefined)
            {
                props.set_searching(true);
            }

        },1000);
    }

    useEffect(() => {
    },[access]);

    useEffect(() => {
        if(props.searching !== undefined && props.searching == false)
        {
            set_searching(false);
        }
    },[props]);

    useEffect(() => {
        if (user_id !== undefined && user_data.store_id !== undefined) {
          set_access(user_data.access.split(','));
        }
      }, [user_id, user_data]);

    return(
        <>
            <Navbar className="bg-body-tertiary nav_head">
                <Container className="flex-wrap">
                    <Navbar.Brand style={{cursor:'pointer'}} onClick={() => {navigate('/categories')}}  className="me-auto ms-auto me-sm-0 ms-sm-0">
                        <h4 style={{margin:0}}>BHAGWATI METALS</h4>
                    </Navbar.Brand>
                    <Form className="search_block">
                        <Row className="align-items-center justify-content-md-end ">
                        <Col sm="auto" md="8">

                        </Col>

                            <Col sm="auto">
                                <Button variant="danger" onClick={() => {logout();navigate('/')}}>
                                    <img src= {Logout_Icon} className="d-inline-block" alt="Logout Icon" /> Logout
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Navbar>
            {
                props.active_bc !== undefined && (props.active_bc == 'product' || props.active_bc == 'product_meta')
                ?
                    <Container>
                        <div className="justify-content-center row">
                            <div className="col-md-10 col mb-3 col">
                                <nav style={{padding: "15px",background: "#ffffff",borderRadius: "4px",boxShadow: "0 0 16px 0 rgb(172, 172, 194, .25)",marginTop: "30px"}}>
                                    <ol className="breadcrumb" style={{margin:0}}>
                                        {
                                            props.active_bc == 'category'
                                            ?
                                                null
                                            : null
                                        }
                                        {
                                            props.active_bc == 'product'
                                            ?
                                                <>
                                                    <li className="breadcrumb-item"><Link to={'/categories'}>Home</Link></li>
                                                    <li className="breadcrumb-item active">{props.breadcrumb_product_name !== undefined && props.breadcrumb_product_name !== null && props.breadcrumb_product_name != '' ? props.breadcrumb_product_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</li>
                                                </>
                                            : null
                                        }
                                        {
                                            props.active_bc == 'product_meta'
                                            ?
                                                <>
                                                    <li className="breadcrumb-item"><Link to={'/categories'}>Home</Link></li>
                                                    <li className="breadcrumb-item"><Link to={`/categories/${cat_id}`}>{props.breadcrumb_product_name !== undefined && props.breadcrumb_product_name !== null && props.breadcrumb_product_name !== '' ? props.breadcrumb_product_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : null}</Link></li>
                                                    <li className="breadcrumb-item active">{props.breadcrumb_product_meta_name !== undefined && props.breadcrumb_product_meta_name !== null && props.breadcrumb_product_meta_name != '' ? props.breadcrumb_product_meta_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : null}</li>
                                                </>
                                            : null
                                        }
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </Container>
                : null
            }

        </>
    )
}

export default Header;
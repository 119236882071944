export const setLoginDetails = (user_id) => {
    return {
        type : 'SET_USER_ID',
        payload : user_id
    }
}

export const setUserDetails = (user_data) => {
    return {
        type : 'SET_USER_DETAILS',
        payload : user_data
    }
}

export const setProductMetaCayegory = (category_id) => {
    return {
        type : 'SET_PRODUCT_META_CAT_ID',
        payload : category_id
    }
}
const initialValue = {};

const set_user_data = (state = initialValue,action) =>{
    switch (action.type) {
        case "SET_USER_DETAILS":            
            return action.payload;
            break;        
    
        default:
            return state;
            break;
    }
}



export default set_user_data;
import React, { useEffect, useState, useRef } from 'react';
import { is_user_is_login, notify } from '../../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/loader';
import Header from '../../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Col, Form, Row, Container, Button, Navbar, ListGroup, Dropdown, Table, Modal } from 'react-bootstrap';
import Logo from '../../assets/images/logo.svg';
import Chevronright from '../../assets/images/chevron-right.svg';
import Angleright from '../../assets/images/arrow-angle-right.svg';
import DeleteIc from '../../assets/images/delete-ic.svg';
import DeleteDarkIc from '../../assets/images/delete-dark-ic.svg';
import SaveIc from '../../assets/images/save-ic.svg';
import EditIc from '../../assets/images/edit-ic.svg';
import Dots from '../../assets/images/dots.svg';
import Search from '../../assets/images/search-ic.svg';
import Addnew from '../../assets/images/add_new_ic.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

const View = () => {
    const edit_category_input_ref = useRef('');
    const [delete_category_name,set_delete_category_name] = useState('');
    const [delete_category_id,set_delete_category_id] = useState('');
    const [edit_category_id,set_edit_category_id] = useState(undefined);
    const [edit_category_input,set_edit_category_input] = useState('');
    const [edit_category_input_error,set_edit_category_input_error] = useState('');
    const [update_category_btn_disabled,set_update_category_btn_disabled] = useState(false);
    const [delete_category_btn_disabled,set_delete_category_btn_disabled] = useState(false);
    const user_id = useSelector((state) => state.set_user_id);
    const user_data = useSelector((state) => state.set_user_data);
    const location = useLocation();
    const [allow_access,set_allow_access] = useState(false);
    const [modal_show,set_modal_show] = useState(false);
    const [conf_modal_show,set_conf_modal_show] = useState(false);
    const navigate = useNavigate();
    const [categories, set_categories] = useState([]);
    const [access, set_access] = useState([]);
    const [inputText,setinputText] = useState('');
    const [searching,set_searching] = useState(false);
    const inputRef = useRef('');
    let typeCheck = undefined;
    const [add_modal_show,set_add_modal_show] = useState(false);
    const add_category_input_ref = useRef('');
    const [add_category_input_error,set_add_category_input_error] = useState('');
    const [add_category_btn_disabled,set_add_category_btn_disabled] = useState(false);

    const add_modal_close = () =>{
        set_add_modal_show(false);
    }

    const open_add_cat_modal = () => {
        set_add_category_btn_disabled(false);
        set_add_category_input_error(false);
        set_add_modal_show(true);
    }

    const add_category = async () => {
        const add_cat_name = add_category_input_ref.current.value;
        if(add_cat_name == '')
        {
            set_add_category_input_error('Required field.');
        }else{
                set_add_category_btn_disabled(true);
                const formData = {
                    user_id: user_id,
                    store_id: user_data.store_id,
                    name: add_category_input_ref.current.value
                };
                const response = await axios.post(`${API_URL}api/category/create`, formData).then(response => {
                    if (response.data !== undefined && response.data.status == 'success') {
                        notify(toast,'success', response.data.message);
                    }
                    get_categories();
                    set_add_modal_show(false);
                    set_add_category_btn_disabled(false);
                })
                .catch(error => {
                    set_add_category_btn_disabled(false);
                    if (axios.isAxiosError(error) && error.response) {
                        // setErrorMessage(error.response.data.message);
                        notify(toast,'error', error.response.data.message);
                    } else {
                        console.error(error);
                        notify(toast,'error', error);
                    }
                });
        }
    }

    const inputChange = () => {
        clearTimeout(typeCheck);
        typeCheck = setTimeout(function(){
            set_searching(true);
            if(setinputText !== undefined)
            {
                setinputText(inputRef.current.value);
            }

            if(set_searching !== undefined)
            {
                set_searching(true);
            }

        },1000);
    }


    useEffect(() => {
        if (user_id !== undefined && user_data.store_id !== undefined) {
            set_access(user_data.access.split(','));

            get_categories();
        }
    }, [user_id, user_data]);

    const get_categories = async() => {
        const response = await axios.post(`${API_URL}api/category/getAll`, { user_id: user_id, store_id: user_data.store_id }).then(response => {
            if (response.data.status == 'success') {
                set_categories(response.data.message.reverse());
                if(inputText != '')
                {
                    filter_category();
                }
            }
            set_allow_access(true);
        })
            .catch(error => {
                set_allow_access(true);
                if (axios.isAxiosError(error) && error.response) {
                    // setErrorMessage(error.response.data.message);
                } else {
                    console.error(error);
                }

            });
    }

    useEffect(() => {
    },[access]);

    useEffect(() => {
        if(searching == true)
        {
            filter_category();
        }
    },[searching]);

    const filter_category = async() => {
        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            search: inputText
        };
        const response = await axios.post(`${API_URL}api/category/search`, formData).then(response => {
            if (response.data.status == 'success') {
                set_categories(response.data.message.reverse());
            }
        })
        .catch(error => {
            set_allow_access(true);
            set_categories([]);
            if (axios.isAxiosError(error) && error.response) {
                // setErrorMessage(error.response.data.message);
            } else {
                console.error(error);
            }

        });
        setTimeout(function(){
            set_searching(false);
        },0);
    }

    const modal_close = () => {
        set_modal_show(false);
    }

    const conf_modal_close = () => {
        set_conf_modal_show(false);
    }

    const edit_category = async (category_id,category_name) => {
        set_edit_category_input_error('');
        if(category_id != '' && category_name != '')
        {
            set_edit_category_input(123);
            const formData = {
                user_id: user_id,
                store_id: user_data.store_id,
                category_id: category_id
            };
            const response = await axios.post(`${API_URL}api/category/getCategory`, formData).then(response => {
                if (response.data.status == 'success' && response.data.message.name == category_name && response.data.message.id == category_id && response.data.message.store_id == user_data.store_id) {
                    set_edit_category_input(response.data.message.name);
                    set_edit_category_id(response.data.message.id);
                    set_modal_show(true);
                }else{
                    notify(toast,'error', 'Category is not available');
                }
            })
            .catch(error => {
                set_allow_access(true);
                if (axios.isAxiosError(error) && error.response) {
                    // setErrorMessage(error.response.data.message);
                    notify(toast,'error', error.response.data.message);
                } else {
                    console.error(error);
                    notify(toast,'error', error);
                }

            });
        }
    }

    const update_category = async() => {

        if(edit_category_input_ref.current.value != '' && edit_category_id != '')
        {
            set_update_category_btn_disabled(true);
            const formData = {
                user_id: user_id,
                store_id: user_data.store_id,
                category_id: edit_category_id,
                name: edit_category_input_ref.current.value
              };
            const response = await axios.post(`${API_URL}api/category/update`, formData).then(response => {
                if (response.data.status == 'success') {
                    notify(toast,'success', response.data.message);
                    get_categories();
                }
                set_update_category_btn_disabled(false);

            })
            .catch(error => {
                set_update_category_btn_disabled(false);

                set_allow_access(true);
                if (axios.isAxiosError(error) && error.response) {
                    // setErrorMessage(error.response.data.message);
                    notify(toast,'error', error.response.data.message);
                } else {
                    console.error(error);
                    notify(toast,'error', error);
                }

            });
        }
        else{
            set_edit_category_input_error('Required field.');
        }
    }

    const deleteConfirm = async(category_id,category_name) => {
        if(category_id != '' && category_name != '')
        {
            set_edit_category_input(123);
            const formData = {
                user_id: user_id,
                store_id: user_data.store_id,
                category_id: category_id
                };
            const response = await axios.post(`${API_URL}api/category/getCategory`, formData).then(response => {
                if (response.data.status == 'success' && response.data.message.name == category_name && response.data.message.id == category_id && response.data.message.store_id == user_data.store_id) {
                    set_delete_category_name(response.data.message.name);
                    set_delete_category_id(response.data.message.id);
                    set_conf_modal_show(true);
                }else{
                    notify(toast,'error', 'Category is not available');
                }
            })
            .catch(error => {
                set_allow_access(true);
                if (axios.isAxiosError(error) && error.response) {
                    // setErrorMessage(error.response.data.message);
                    notify(toast,'error', error.response.data.message);
                } else {
                    console.error(error);
                    notify(toast,'error', error);
                }

            });
        }
    }

    const delete_category = async() => {
        set_delete_category_btn_disabled(true);
        const formData = {
            user_id: user_id,
            store_id: user_data.store_id,
            category_id: delete_category_id,
          };
        const response = await axios.post(`${API_URL}api/category/delete`, formData).then(response => {
            if (response.data.status == 'success') {
                notify(toast,'success', response.data.message);
                get_categories();
            }
            set_delete_category_btn_disabled(false);
            set_conf_modal_show(false);
        })
        .catch(error => {
            set_delete_category_btn_disabled(false);

            set_allow_access(true);
            if (axios.isAxiosError(error) && error.response) {
                // setErrorMessage(error.response.data.message);
                notify(toast,'error', error.response.data.message);
            } else {
                console.error(error);
                notify(toast,'error', error);
            }

        });
        set_delete_category_btn_disabled(false);
    }

    return (
        <>
            <Helmet>
                <title>Categories - Stock Inventody</title>
            </Helmet>
            <Loader allow_access={allow_access} ></Loader>
            <ToastContainer />
            <Header inputText={inputText} setinputText={setinputText} searching={searching} set_searching={set_searching} active_bc="category"></Header>

            <Container>
                <div className="category_list_block my-3 py-5">
                    <Row className="justify-content-center">
                        {
                            (<Col className="col-md-10 col d-flex justify-content-between mb-3">
                                <div className="d-flex align-items-center search_bar position-relative">
                                    <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    className=" mr-sm-2 search_field"
                                    ref={inputRef}
                                    onKeyUp={() => {inputChange()}}
                                    disabled={(searching !== undefined && searching == true ) ? true : false}
                                    />
                                    <img
                                    src= {Search}
                                    width="20"
                                    height="20"
                                    className="d-inline-block align-top search_icon"
                                    alt="search icon"
                                    />
                                </div>
                                {
                                    access.includes('AddCategory') ?
                                    <Button onClick={() => open_add_cat_modal()} className="primary mt-sm-0 mt-2 d-flex justify-content-around align-items-center add_new_btn ">
                                        <img src= {Addnew} width="15" height="15" className="d-inline-block" alt="add icon" /> ADD NEW
                                    </Button>
                                    : null
                                }

                            </Col>)
                        }
                        <Col xs md="10">
                            <div className="category_list_inner">
                                <Table responsive="sm" size="sm" className={searching == true ? 'catgeory_list_group searching' : 'catgeory_list_group'}>
                                    <thead>
                                        <tr>
                                            <th style={{width:"45px"}}></th>
                                            <th style={{textAlign:"left"}}>Category List</th>
                                            {
                                                access.includes("UpdateCategory") || access.includes("DeleteCategory")
                                                ?
                                                <th className="option_item">Options</th>
                                                : null
                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            categories.length > 0
                                            ?
                                                categories.map((category,category_key) => {
                                                    return (
                                                        <tr key={category_key}>
                                                            <td style={{width:"10px"}}>
                                                                <div className="m-auto category_link" style={{width:"20px"}}>
                                                                    <Link to={`/categories/${category.id}`} className="d-flex align-items-center justify-content-center">
                                                                        <img
                                                                            src={Angleright}
                                                                            width="15"
                                                                            height="15"
                                                                            className="d-inline-block align-top"
                                                                            alt="Arrow Angler right"
                                                                        /></Link>
                                                                </div>
                                                            </td>
                                                            <td><Link to={`/categories/${category.id}`}>{category.name}</Link></td>
                                                            {

                                                                access.includes("UpdateCategory") || access.includes("DeleteCategory")
                                                                ?
                                                                    <td className="option_data">
                                                                        <div className="ms-auto category_options">
                                                                            <Dropdown>
                                                                                <Dropdown.Toggle variant="light" id="dropdown-1" className="toggle_dots m-auto">
                                                                                    Actions
                                                                                </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        {
                                                                                            access.includes("UpdateCategory") ? (<Dropdown.Item onClick={() => edit_category(category.id,category.name)}><img src={EditIc} width="15" height="15" className="d-inline-block me-2" alt="Edit Icon"/> Edit</Dropdown.Item>) : null
                                                                                        }
                                                                                        {
                                                                                            access.includes("DeleteCategory") ? (<Dropdown.Item onClick={() => deleteConfirm(category.id,category.name)}><img src={DeleteDarkIc} width="15" height="15" className="d-inline-block me-2" alt="Delete Icon"/> Delete</Dropdown.Item>) : null
                                                                                        }
                                                                                    </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                : null

                                                            }

                                                        </tr>
                                                    )
                                                })
                                            : (<tr>
                                                <td colSpan={access.includes("UpdateCategory") || access.includes("DeleteCategory") ? 3 : 2}>
                                                    <h5 className="m-auto text-center">
                                                        No categories found
                                                    </h5>
                                                </td>
                                            </tr>)
                                        }

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal show={modal_show} onHide={modal_close}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="form_input_block" controlId="formGroupEmail">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control type="text" ref={edit_category_input_ref} defaultValue={edit_category_input} placeholder="Enter category" onKeyUp={() => {set_edit_category_input_error('')}} />
                            {edit_category_input_error != '' ? (<div className="text-danger mt-2 ms-2">{edit_category_input_error}</div>) : null}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={modal_close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {update_category()}} disabled={update_category_btn_disabled == true ? true : false}>
                        <img src={SaveIc} width="15" height="15" className="d-inline-block" alt="Save Icon"/> Save Changes
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={conf_modal_show} onHide={conf_modal_close}>
                    <Modal.Header closeButton>
                    <Modal.Title>Delete Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Are you sure you want to delete category <strong>{delete_category_name}</strong> ?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={conf_modal_close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {delete_category()}} disabled={delete_category_btn_disabled == true ? true : false}>
                        <img src={DeleteIc} width="15" height="15" className="d-inline-block" alt="Delete Icon"/> Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={add_modal_show} onHide={add_modal_close}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="form_input_block" controlId="formGroupEmail">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control type="text" ref={add_category_input_ref} placeholder="Enter category" onKeyUp={() => {set_add_category_input_error('')}} />
                            {add_category_input_error != '' ? (<div className="text-danger mt-2 ms-2">{add_category_input_error}</div>) : null}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={add_modal_close}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {add_category()}} disabled={add_category_btn_disabled == true ? true : false}>
                        <img src= {Addnew} width="15" height="15" className="d-inline-block" alt="add icon" /> Add Category
                    </Button>
                    </Modal.Footer>
                </Modal>
            </Container>

        </>
    );
};

export default View;
